import sessionClient from 'apiHelpers/sessionClient';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Hero, { HeroData } from 'components/Hero';
import Layout from 'components/Layout';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { useAnalyticsPageVariables } from 'helpers/useAnalyticsPageVariables';
import { useClearReduxState } from 'helpers/useClearReduxState';
import useRequestHandler from 'helpers/useRequestHandler';
import { PageMeta } from 'types/contentStack';

type SignedOutPageProps = {
  data: SignedOutPageData;
};

const redirectToMOLogOut = (): void => {
  if (process.env.GATSBY_MO_LOGOUT_URL) {
    window.location.replace(process.env.GATSBY_MO_LOGOUT_URL);
  }
};

const SignedOutPage: React.FC<SignedOutPageProps> = ({ data }) => {
  const page = data.csSignedOutPage;
  const { meta } = processPageMeta(page.page_meta, page.url);
  useAnalyticsPageVariables(
    {
      pageCategories: [],
      pageType: 'SignedOutPage',
    },
    []
  );

  const clearReduxState = useClearReduxState();
  const requestHandler = useRequestHandler();

  useEffect(() => {
    clearReduxState();
    requestHandler(() => sessionClient.logout().then(redirectToMOLogOut), false);
  }, [clearReduxState, requestHandler]);

  return (
    <Layout
      meta={meta}
      pageType="signedOut"
      showAlertNotification={page.show_alert_notification ?? undefined}>
      <Hero hero={page.hero} />
    </Layout>
  );
};
export default SignedOutPage;

type SignedOutPageData = {
  csSignedOutPage: {
    title: string;
    url: string;
    hero: HeroData;
    page_meta: PageMeta;
    show_alert_notification: boolean | null;
  };
};

export const query = graphql`
  query($id: String!) {
    csSignedOutPage(id: { eq: $id }) {
      title
      url
      show_alert_notification
      ...HeroSignedOut
      page_meta {
        ...MetaDataTagsSignedOut
      }
    }
  }
`;
