import { UserSession } from 'types/userSession';
import apiClient from './apiClient';

export type SessionClient = {
  refreshSession: () => Promise<void>;
  logout: () => Promise<void>;
  getSession: () => Promise<UserSession>;
};

const sessionClient: SessionClient = {
  refreshSession: () => apiClient.post('session'),
  logout: () => apiClient.delete('session'),
  getSession: () => apiClient.get('session'),
};

export default sessionClient;
