import { useCallback } from 'react';
import { RESET_STATE } from 'state/actions';
import useDispatch from 'state/useDispatch';

export const useClearReduxState = (): (() => void) => {
  const dispatch = useDispatch();

  return useCallback((): void => {
    dispatch({ type: RESET_STATE });
  }, [dispatch]);
};
